import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Main } from '../components/Main';
import { Secuence } from '../components/Secuence';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';
import { Link } from '../components/Link';

const styles = (theme) => ({
  root: {},
  albums: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  album: {
    padding: [0, 0, 20],
    width: '100%',
  },
  albumCover: {
    display: 'block',
    margin: [0, 0, 20],
    width: '100%',

    '& img': {
      margin: 0,
      width: '100%',
      maxWidth: '100%',
    },
  },
  albumFrame: {
    display: 'block',
    width: '100%',
  },

  '@media screen and (min-width: 768px)': {
    album: {
      padding: 10,
      width: '50%',
    },
  },
});

class Music extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
  };

  render() {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <Secuence stagger>
          <h1>
            <Text>Vixen Race</Text>
          </h1>
          <div className={classes.albums}>
            <div className={classes.album}>
              <Fader className={classes.albumCover}>
                <img alt="Vixen Race 1" src="/images/race1.png" />
                <br />
                <p>
                  <b>Race</b>: Urban Ninja <br />
                  <b>Origin</b>: Toshiro City <br />
                  <b>Nature</b>: Secretive <br />
                  <b>Ability</b>: Stealth
                </p>
              </Fader>
            </div>
            <div className={classes.album}>
              <Fader className={classes.albumCover}>
                <img alt="Vixen Race 2" src="/images/race2.png" />
                <br />
                <p>
                  <b>Race</b>: Woodland Mercenary <br />
                  <b>Origin</b>: Blossom Forest <br />
                  <b>Nature</b>: Mystical <br />
                  <b>Ability</b>: Heal
                </p>
              </Fader>
            </div>
            <div className={classes.album}>
              <Fader className={classes.albumCover}>
                <img alt="Vixen Race 3" src="/images/race3.png" />
                <br />
                <p>
                  <b>Race</b>: Desert Nomad <br />
                  <b>Origin</b>: Kaya Township <br />
                  <b>Nature</b>: Noble <br />
                  <b>Ability</b>: Spirit Attack
                </p>
              </Fader>
            </div>
            <div className={classes.album}>
              <Fader className={classes.albumCover}>
                <img alt="Vixen Race 4" src="/images/race4.png" />
                <br />
                <p>
                  <b>Race</b>: Blockchain Hacker <br />
                  <b>Origin</b>: Koumaira City <br />
                  <b>Nature</b>: Dark <br />
                  <b>Ability</b>: Short Circuit
                </p>
              </Fader>
            </div>
          </div>
        </Secuence>
      </Main>
    );
  }
}

export default withStyles(styles)(Music);
